import React, { createContext, useContext, useState } from "react";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { dateRange } from "app/constants/dateRange";
import { timezone } from "app/constants/timezone";

const DateContext = createContext({
  startDate: startOfDay(addDays(new Date(), -dateRange)),
  endDate: endOfDay(new Date()),
});

function DateProvider({ children }) {
  const [globalDate, setGlobalDate] = useState({
    startDate: startOfDay(addDays(new Date(), -dateRange)),
    endDate: endOfDay(new Date()),
  });
  const [globalTimezone, setGlobalTimezone] = useState(timezone);

  return (
    <DateContext.Provider
      value={{ globalDate, setGlobalDate, globalTimezone, setGlobalTimezone }}
    >
      {children}
    </DateContext.Provider>
  );
}

const useDate = () => useContext(DateContext);

export { useDate, DateProvider };
